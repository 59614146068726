import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import style from "./about.module.css"

class About extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="About"
          description="Who is Andreas Karpasitis"
          keywords="Karpasitis, Andreas, Photographer, Writer, Developer, Engineer, Barcelona, Photographer, Content, Creator"
          url="https://www.karpasitis.net/about"
        />
        <div className={style.container}>
          <header>
            <h1 className={style.title}>About</h1>
          </header>
          <h3 className={style.text}>
            From Cyprus and recently moved from Barcelona back to Larnaca, Cyprus. I'm a freelancer and at this moment I'm contracted as a
            Fullstack Product Developer for a <a href="https://limbic.ai" target="_blank" rel="noreferrer">UK based startup</a> which focuses on improving mental health
            and access to mental health services.
          </h3>
          <h3 className={style.text}>
            Graduated from University of Miami with a Bachelor of Science,
            double majoring in Computer Science and Motion Pictures, in 2008.
            In 2017, joined a Web Development bootcamp and rebooted my
            interest in software development and coding.
          </h3>
          <h3 className={style.text}>
            Worked in television, finance and software development. Lived in
            Larnaca, Miami, Zurich and Barcelona.
          </h3>
          <h3 className={style.text}>
            Constantly looking for new and creative Photography and Videography projects and
            collaborations.
          </h3>
          <h4 className={style.text}>
            Current Location: Larnaca, Cyprus (with flexibility to travel)
          </h4>
        </div>
      </Layout>
    )
  }
}

export default About
